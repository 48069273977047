.table-modified td {
  padding: 6px;
  vertical-align: middle !important;
  font-size: 14px;

  @media (max-width: 767px) {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 12px;
    padding: 6px 3px;
  }
}

.scroll-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.scroll-button.visible {
  opacity: 1;
  visibility: visible;
}

.statment__from {
  margin: 0;
  font-weight: 400;
  display: block;
  font-size: 16px;
  color: white;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.no-wrap {
  white-space: nowrap;
}

.nav-link {
  font-size: 20px !important;

  @media (max-width: 767px) {
    font-size: 16px !important;
  }
}

.printDate {
  display: none;
}

.onlyPrint__header {
  display: none;
}

.text-danger {
  margin-bottom: 0 !important;
}

.page-link {

  padding: 10px 14px !important;

  @media (max-width: 767px) {
    padding: 8px !important;
    font-size: 0.85rem !important;
  }
}

@media print {

  body {
    font-family: 'Roboto Condensed', sans-serif;
    text-align: center;
    margin-top: 30px;
    color: #000000;
    font-size: 14px;
    background-color: white;


  }

  td, th {
    border: 1px solid #000000;
    padding: 5px;
    border-collapse: collapse;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 1.15;
  }

  tr {
    --bs-table-accent-bg: white !important;
    --bs-table-striped-color: black !important;
    color: black;
  }

  nav {

    color: black !important;
  }

  .navbar-brand {
    color: black !important;
  }

  h1, h2 {
    display: none;
  }

  form {
    display: none;
  }

  .last__day {
    display: none;
  }

  .statment__from {
    color: black;
  }

  .scroll__button {
    display: none;
  }

  .btn__print {
    display: none;
  }

  .datesLastStatment {
    display: none;
  }

  .printDate {
    display: block;
    color: black;
    margin: 20px 0 15px 430px;
  }

  .navbar {
    display: none !important;
  }

  .text-primary {
    color: black !important;
  }

  .box__latestSummary {
    display: none;
  }

  .onlyPrint__header {
    display: block;
    font-size: 20px;
    font-weight: bold;
    color: black;
  }
}